import gql from 'graphql-tag';

export const CART = gql`
  query Cart {
    cart @client {
      item
    }
  }
`;

export const UPDATE_CART = gql`
  mutation UpdateCart($item: Int) {
    updateCart(item: $item) @client
  }
`;
// createUpdateCart
export const CREATE_UPDATE_CART = gql`
  mutation CreateUpdateCart($input: [CartLineInputType]) {
    createUpdateCart(input: $input) {
      cart {
        id
      }
    }
  }
`;
// cart query
export const USER_CARTS = gql`
  query UserCarts {
    userCarts {
      id
      cartLines {
        id
        created
        amount
        productVariant {
          id
          name
          longName
          price
          discountedPrice
          onSale
          product {
            id
            code
            name
            images {
              id
              image
            }
          }
        }
      }
    }
  }
`;

// delete cart line
export const DELETE_CART_LINE = gql`
  mutation DeleteCartLine($cartLineId: ID) {
    deleteCartLine(cartLineId: $cartLineId) {
      result
    }
  }
`;

// delete cart
export const DELETE_CART = gql`
  mutation DeleteCart($cartId: ID) {
    deleteCart(cartId: $cartId) {
      result
    }
  }
`;

// updateAmount
export const UPDATE_AMOUNT = gql`
  mutation UpdateAmount($isIncrease: Boolean, $cartLineId: ID) {
    updateAmount(isIncrease: $isIncrease, cartLineId: $cartLineId) {
      result
    }
  }
`;
